/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import firebase from 'firebase/app';

import { AuthUser } from 'src/app/shared/models';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { Admin, DistrictShort, SchoolShort } from '../graphql';
import { AlertService } from './alert.service';
import { FirebaseProvider } from '../enums/firebaseProvider';
import { URL_ASSETS } from '../const';
import { resourcesImages } from '../const/images-resources';
import { AuthCookieService } from './cookies.service';
import _ from 'underscore';
import { MatDialog } from '@angular/material/dialog';
import { ZendeskChatService } from './zendesk-chat.service';
import { CookieTkService } from './cookie.tk.service';
import { BehaviorSubject, Observable, ReplaySubject, timer } from 'rxjs';
import { IntercomChatService } from './intercom.chat.service';

const networkError = 'Unable to connect to authentication provider, try again later or contact your provider.';
declare var sessionStorage: any;
@Injectable({
  providedIn: 'root'
})

export class AuthService {
  userData: any;
  private loginFailed$ = new ReplaySubject<boolean>();
  public loginFailed = this.loginFailed$.asObservable();
  private authToken$ = new ReplaySubject<string>();
  public authToken = this.authToken$.asObservable();
  private authUser$ = new ReplaySubject<AuthUser>();
  public authUser = this.authUser$.asObservable();
  token!: string;
  
  private tokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  public token$: Observable<string | null> = this.tokenSubject.asObservable();

  constructor(public afs: AngularFirestore, public afAuth: AngularFireAuth, private matDialog: ModalService, private mDialog: MatDialog,
              public router: Router, public ngZone: NgZone, private alertService: AlertService, private authCookiesServ: AuthCookieService,
              private zendeskChatService: ZendeskChatService, private cookieTkService: CookieTkService,
              private intercomChatService: IntercomChatService) {
    /* Saving user data in sessionStorage when
      logged in and setting up null when logged out */
    this.afAuth.authState.subscribe((user:any) => {
      if (user) {
        this.userData = user;
        this.updateUserFirebase(user);
        if( user._lat){
          this.token = user._lat;
          this.tokenSubject.next(this.token);
        }
     
      }
    });
  }

/* Sign up - Tested */
public signUp = (email: string, password: string) => {
  this.afAuth.createUserWithEmailAndPassword(email, password)
    .then((result) => {
      this.setUserData(result.user);
    }).catch((error) => {
      this.alertService.error(error.message, 'Authentication error');
    });
}
  // Sign in with email/password
 public signIn = (email: string, password: string) => {
    return this.afAuth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.setUserData(result.user);
        this.setLoginFailed(false);
      }).catch((error) => {
        this.alertService.error(error.message, 'Authentication error');
        this.setLoginFailed(true);
      });
  }

  getUserPhotoURL(user?: any): string {
    const photoURL = user.photoURL ? user.photoURL :
    user?.providerData?.length > 0 && user.providerData[0].photoURL ? user.providerData[0].photoURL:
    URL_ASSETS + resourcesImages['blank-profile'];
    return photoURL;
  }

  updateUserFirebase(user?: any): void {
    const userData: AuthUser = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: this.getUserPhotoURL(user),
      emailVerified: user.emailVerified
    };
    sessionStorage.setItem('user', JSON.stringify(userData));
  }

/* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  setUserData(user: any, signup?: boolean): void {
    const userData: AuthUser = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: this.getUserPhotoURL(user),
      emailVerified: user.emailVerified
    };
    this.authCookiesServ.setAuth('token', user._lat);
    this.authCookiesServ.setAuth('user', JSON.stringify(userData));
    const subscribeAuth = this.afAuth.idToken.subscribe((token) => {
      this.setToken(token);
      sessionStorage.setItem('user', JSON.stringify(userData));
      this.setUser(userData);
      subscribeAuth.unsubscribe();
    });
  }

  setUserDataPromise(user: any, tk?:string):  Promise<boolean> {
    return new Promise((resolve, reject) => {
      const userData: AuthUser = {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        photoURL: this.getUserPhotoURL(user),
        emailVerified: user.emailVerified
      };

       const tkAux = user._lat || tk;
       if(tkAux){     
        this.token = tkAux;
        this.cookieTkService.setCookie(tkAux).subscribe(
          response => {
            this.authCookiesServ.setAuth('user', JSON.stringify(userData));
            const subscribeAuth = this.afAuth.idToken.subscribe((token) => {
              this.tokenSubject.next(this.token);
              this.setToken(token);
              sessionStorage.setItem('user', JSON.stringify(userData));
              this.setUser(userData);
              resolve(true);
              subscribeAuth.unsubscribe();
            });
          },
          error => {
            console.error('Error setting cookie', error);
          }
        );
       }
    });
  }

   // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
      const user = JSON.parse(JSON.stringify(sessionStorage.getItem('user')));
      return (user !== null && user.emailVerified !== false) ? true : false;
  }

  // Sign in with Google
  googleAuth = (signup?: boolean) => {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account'
    });
    return this.authLogin(provider, signup);
  }

  authLogin = (provider: any, signup?: boolean) => {
    return this.afAuth.signInWithPopup(provider)
      .then((result) => {
         this.setUserDataPromise(result.user).then((value: boolean) => {
        });
      }).catch((error) => {
         this.showErrorMessage(error);
    });
  }

  /**Sign in with Google */
  // Auth logic to run auth providers
  authLoginPromise = (): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        const provider = new firebase.auth.GoogleAuthProvider();
        provider.setCustomParameters({
          prompt: 'select_account'
        });
        return this.afAuth.signInWithPopup(provider)
          .then((result) => {
            this.setUserDataPromise(result.user).then((value: boolean) => {
              resolve(value);
            });
          }).catch((error) => {
            resolve(false);
          this.showErrorMessage(error);
        });
    });
  }

    signInWithRedirect = (provider:any): Promise<void> => {
      provider.setCustomParameters({
        prompt: 'select_account',
      });
      return this.afAuth.signInWithRedirect(provider);
    }

  showErrorMessage(error:any): void {
    const isNetworkError = error?.message.includes('network error');
    const msg = isNetworkError ? networkError : error?.message;
    this.alertService.error(msg, 'Authentication error');
    this.setLoginFailed(true);
  }

  // Sign out
  signOut = () => {

   this.afAuth.signOut().then(() => {
      timer(200).subscribe(
        () => {
          console.log("---------------signOut-----------------");
          sessionStorage.clear();
          this.authCookiesServ.deleteAuth('token');
          this.authCookiesServ.deleteAuth('user');
          this.matDialog.closeAll();
          this.mDialog.closeAll();
          this.intercomChatService.shutdownIntercom();
          window.location.replace('/login');    
      });
    });
  }
  /********************************************************************/
  // Sign in with Microsoft
  microsoftAuth = (signup?: boolean) => {
    const provider = new firebase.auth.OAuthProvider(FirebaseProvider.Microsoft);
    provider.setCustomParameters({
      prompt: 'select_account',
    });
    return this.oauthLogin(provider, signup);
  }

  classLinkAuth = () => {
      const provider = new firebase.auth.OAuthProvider(FirebaseProvider.ClassLink);
      provider.setCustomParameters({
        prompt: 'select_account',
      });
      return this.oauthLogin(provider, true);
  }

  oauthLoginExternal = (provider: any) => {
    return new Promise((resolve, reject) => {
      this.afAuth.signInWithPopup(provider)
      .then((result) => {
         resolve(result.user ? true : false);
       })
      .catch((error) => {
         resolve(false);
       });
    });
  }

  oauthLogin = (provider: any, signup?: boolean) => {
    return this.afAuth.signInWithPopup(provider)
      .then((result) => {
        this.setUserData(result.user, signup);
      }).catch((error) => {
        if (error.email && error.credential && error.code === 'auth/account-exists-with-different-credential') {
          this.fetchSignInMethodsForEmail(error, signup);
        } else if (error.message.includes("error_description=AADSTS65004")) {
          this.setLoginFailed(true);
        } else {
          this.showErrorMessage(error);
        }
      });
  }
    // Firebase SignInWithPopup
  oAuthProvider = (provider: any) => {
    return this.afAuth.signInWithPopup(provider).catch((error) => {
      return error;
    });
  }

  fetchSignInMethodsForEmail = (error: any, signup?: boolean): any => {
    return firebase.auth().fetchSignInMethodsForEmail(error.email)
      .then(providers => {
        switch (providers[0]) {
          case 'password':
            break;
          default:
            const authProvider = new firebase.auth.OAuthProvider(providers[0]);
            this.oAuthProvider(authProvider).then((result) => {
              if (result && result?.code === 'auth/popup-blocked') {
                this.alertService.warn(result.message, 'Authentication warning');
                this.setLoginFailed(true);
                return;
              }
              if (result.user) {
                result.user.linkWithCredential(error.credential).then((usercred: any) => {
                  this.setUserData(result.user);
                }, () => {
                  this.alertService.error(error, 'Authentication error');
                  this.setLoginFailed(true);
                });
              } else {
                this.alertService.error(result.message, 'Authentication error');
                this.setLoginFailed(true);
                return;
              }
            }, () => {
              this.alertService.error(error, 'Authentication error');
              this.setLoginFailed(true);
            });
            break;
        }
      }).catch((error) => {
        this.alertService.error(error, 'Authentication error');
        this.setLoginFailed(true);
      });
  }

  authWithCustomTk(tk: string, provider: FirebaseProvider): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.afAuth.signInWithCustomToken(tk)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        // ...
        if (!user) {
          this.router.navigate(['/login']);
          this.alertService.error('The specified token is not valid.', 'Invalid token');
          resolve(false);
        } else {
          this.setUserDataPromise(user).then((user) => {
            switch (provider) {
              case FirebaseProvider.ClassLink:
                sessionStorage.setItem('classlinkAuth', "true");
              break;
            }
            resolve(user);
          });
        }
      })
      .catch((error) => {
        const errorMessage = error.message;
        this.alertService.error(errorMessage, 'Invalid token');
        this.router.navigate(['/login']);
        resolve(false);
      });
    });
  }

  async renewToken(): Promise<boolean> {
    try {
      const user = await this.afAuth.currentUser;
      if (user) {
       const tk = await user.getIdToken(true);
        return this.setUserDataPromise(user, tk);
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error renewing token:", error);
      return false;
    }
  }

  setLoginFailed(loginFailed: boolean): void {
    this.loginFailed$.next(loginFailed);
  }

  setToken(token: string | null): void {
    this.authToken$.next(token?.toString());
  }
  setUser(user: AuthUser): void {
    this.authUser$.next(user);
  }

  setCurrent(admin: Admin): void {
    sessionStorage.setItem('current', JSON.stringify(admin));
  }
  removeCurrentFromStorage(): void {
    sessionStorage.removeItem('current');
  }
  getCurrent(): Admin {
    return JSON.parse(sessionStorage.getItem('current'));
  }
  clearStorageDataDistrict(): void {
    const ss = this.getUserFromStorage();
    const tk = this.authCookiesServ.getAuth('token')
    sessionStorage.clear();
    sessionStorage.setItem('user', JSON.stringify(ss));
    this.authCookiesServ.setAuth('token', tk);
    this.authCookiesServ.setAuth('user', JSON.stringify(ss));
  }
  getDistricts(): DistrictShort [] {
    const districts = sessionStorage.getItem('districts');
    if (districts) {
      return JSON.parse(districts);
    } else {
      return [];
    }
  }

  getDistrictById(districtId: string): DistrictShort | null {
    const districts = this.getDistricts();
    return districts.find(x=>x.districtId === districtId)?? null;
  }

  getSchoolsByDistrictId(districtId: string): Array<SchoolShort> {
    const districts = this.getDistricts();
    const d = districts.find((dist) => dist?.districtId === districtId);
    return d?.schools ? d?.schools as Array<SchoolShort>: [];
  }

  setDistricts(districts: DistrictShort []): void {
    sessionStorage.setItem('districts', JSON.stringify(districts));
  }

  getLastDistrict(): DistrictShort | undefined {
    const district = sessionStorage.getItem('lastDistrict');
    const districts = this.getDistricts();
    return districts.find((dist) => dist.districtId === district);
  }

  getLastDistrictId(): string {
    return sessionStorage.getItem('lastDistrict') as string;
  }

  setLastDistrict(districtId: string): void {
    sessionStorage.setItem('lastDistrict', districtId);
  }

  setDistrictSchool(districtId: string): void {
    sessionStorage.setItem('districtSchool', districtId);
  }

  getDistrictSchool(): string {
    return sessionStorage.getItem('districtSchool');
  }

  isDashboardFromInvite(): boolean {
    return sessionStorage.getItem('isDashboardFromInvite') === 'true' || sessionStorage.getItem('isDashboardFromInvite') === 'True' ;
  }

  setDashboardFromInvite(value: boolean): void {
    sessionStorage.setItem('isDashboardFromInvite', value);
  }

  getLastSchool(): string | null {
    let schoolId = '';
    const stLastSchool = sessionStorage.getItem('lastSchool');
    const lastDistrict =  this.getLastDistrict();
    const isSchoolDistrict = lastDistrict?.schools?.find((sch) => sch?.schoolId === stLastSchool);
    if(isSchoolDistrict){
      schoolId = stLastSchool;
    } else {
      if(lastDistrict?.schools && lastDistrict?.schools.length > 0){
        schoolId = lastDistrict?.schools[0]?.schoolId as string;
      }
    }
    return schoolId;
  }

  getLastClass(): string | null {
    return sessionStorage.getItem('lastClass');
  }

  getSchoolbyLastDistrict(): any {
    const schoolId = this.getLastSchool();
    const lastDistrict = this.getLastDistrict();
    return lastDistrict?.schools?.find((sch) => sch?.schoolId === schoolId);
  }

  addSchoolToStorageByDistrict( school: SchoolShort, districtId: string): void {
    const districts = this.getDistricts();
    const district = districts.find((dist) => dist.districtId === districtId);
    if(district){
       let s = district?.schools?.find((sch) => sch?.schoolId === school?.schoolId);
       if(!s){
        district?.schools?.push(school);
        this.setDistricts(districts);
      }
    }
  }
  removeSchoolStorageByDistrict(schoolId: string, districtId: string): void {
    const districts = this.getDistricts();
    const district = districts.find((dist) => dist.districtId === districtId);
    if( district?.schools instanceof Array){
      const districtContainingSchool = _.find(districts, (district) => {
        return _.some(district.schools as Array<SchoolShort>, (school: SchoolShort) => {
          return school.schoolId === schoolId;
        });
      });
      if(districtContainingSchool){
        district.schools = _.reject(district.schools, (school) => {
          return school?.schoolId === schoolId;
        });
        this.setDistricts(districts);
      }
    }
  }

  setLastSchool(schoolId: string): void {
    sessionStorage.setItem('lastSchool', schoolId);
  }

  setLastClass(classId: string): void {
    sessionStorage.setItem('lastClass', classId);
  }

  getUserFromStorage(): any {
    return JSON.parse(sessionStorage.getItem('user') as string);
  }
  setRole(role: string): void {
    sessionStorage.setItem('role', role);
  }
  getRoleFromStorage(): any {
    return sessionStorage.getItem('role');
  }
  isTeacher(): boolean {
    const currentDistrict = this.getLastDistrict();
    const currentSchoolId = this.getLastSchool();
    if(currentDistrict && (currentDistrict.role && currentDistrict.role !== '')) {
      return false;
    }else if (currentDistrict && currentSchoolId) {
      const currentSchool = currentDistrict.schools?.find((sch) => sch?.schoolId === currentSchoolId);
      if (currentSchool){
        return !currentSchool || !currentSchool.role || currentSchool.role === '';
      }else {
        return true;
      }
    }

    return false;
  }

  isEducator(): boolean {
    const admin = this.getCurrent();
    if(admin && admin.districts){
      return _.toArray(admin.districts).length === 0;
    }
   return false;
  }

    
   // Sign in with popup mechanism
   authPopUp = (provider: any) => {
    provider.setCustomParameters({
      prompt: 'select_account'
    });
    return this.authLoginPromisePopUp(provider);
  } 
  authLoginPromisePopUp = (provider: any): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        provider.setCustomParameters({
          prompt: 'select_account'
        });
        return this.afAuth.signInWithPopup(provider)
          .then((result) => {
            this.setUserDataPromise(result.user).then((value: boolean) => {
              resolve(value);
            });
          }).catch((error) => {
            resolve(false);
          this.showErrorMessage(error);
        });
    });
  }

}
