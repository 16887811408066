/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AfterViewInit, Component, HostListener } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { environment } from 'src/environments/environment';
import { AuthService, ThememodeService } from 'src/app/shared/services';
import { defaultUrlAssets } from './shared/const';
import { iconsSvg } from './shared/const/icons-svg';
import {  Router } from '@angular/router';
import { ZendeskChatService } from './shared/services/zendesk-chat.service';
import { AppcuesService } from './shared/services/app-cues.service';
import { IntercomChatService } from './shared/services/intercom.chat.service';

declare var zE: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'k12-portal';
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer,
              private thememodeService: ThememodeService,
              private intercomChatService: IntercomChatService,
              private router: Router, private appCuesService: AppcuesService) {
     

    // Load Color Scheme
    this.thememodeService.load();
    const urlAssets = defaultUrlAssets;
    iconsSvg.forEach(icon => {
      this.matIconRegistry.addSvgIcon(
       icon.icon,
       this.domSanitizer.bypassSecurityTrustResourceUrl(urlAssets + icon.url)
      );
    });

    if (sessionStorage.length === 0 || localStorage.length === 0) {
      // Ask other tabs for session storage
      localStorage.setItem('getSessionStorage', JSON.stringify(Date.now()));
    }
    if(location?.pathname === "/dashboard" || location?.pathname === "/dashboard/"){
      void this.router.navigate([`/`]);
    }
  }

   ngAfterViewInit(): void {
    this.intercomChatService.loadIntercom(environment.intercom.appId);
    if(!this.appCuesService.isLoad){
      this.appCuesService.loadScript();
    }
  }

  // Window storage event
 @HostListener('window:storage', ['$event'])
  public onStorage($event: any) {
    if ($event.key === 'getSessionStorage') {
      if (sessionStorage.length === 0) {
        // Ask other tabs for session storage
        localStorage.setItem('getSessionStorage', JSON.stringify(sessionStorage));
      }
      // Some tab asked for the sessionStorage -> send it
      localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
    } else if ( $event.key === 'sessionStorage' && sessionStorage.length === 0) {
       // sessionStorage is empty -> fill it
      const d = localStorage.getItem('sessionStorage');
      const data = JSON.parse(d as string);
      // tslint:disable-next-line:forin
      for (const key in data) {
        sessionStorage.setItem(key, data[key]);
      }
      localStorage.removeItem('sessionStorage');
    }
  }
}
